<template>
    <!-- begin register -->
    <div class="register register-with-news-feed">
        <!-- begin news-feed -->
        <div class="news-feed bg-purple-transparent-2">
            <div class="text-center pb-3" style="margin-top: 160px;">
                <img :src="logoSumut" width="200" alt="logoSumut" />
            </div>
            <div>
                <h3 class="d-flex justify-content-center">
                    PEMERINTAH PROVINSI SUMATERA UTARA
                </h3>
                <h3 class="d-flex justify-content-center">
                    BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH
                </h3>
                <p
                    class="font-weight-bold d-flex justify-content-center"
                    style="font-size: 15px"
                >
                    Jl. Sisingamangaraja Km 5,5 Medan
                </p>
            </div>
        </div>
        <!-- end news-feed -->
        <!-- begin right-content -->
        <div class="right-content">
            <!-- begin register-header -->
            <h1 class="register-header">
                Daftar Pengguna Baru
                <small
                    >Silahkan isi form di bawah ini untuk membuat akun
                    baru.</small
                >
            </h1>
            <!-- end register-header -->
            <!-- begin register-content -->
            <div class="register-content">
                <form
                    class="margin-bottom-0"
                    @submit.prevent="register"
                    @keydown="form.onKeydown($event)"
                    autocomplete="off"
                >
                    <alert-error
                        :form="form"
                        class="border-danger mb-2"
                    ></alert-error>
                    <!-- <label class="control-label">NPWP Povinsi <span class="text-danger">*</span></label>
					<div class="row row-space-10">
						<div class="col-md-3 m-b-15">
							<input type="text" class="form-control" placeholder="--" />
						</div>
						<div class="col-md-3 m-b-15">
							<input type="text" class="form-control" placeholder="--" />
						</div>
						<div class="col-md-3 m-b-15">
							<input type="text" class="form-control" placeholder="--" />
						</div>
						<div class="col-md-3 m-b-15">
							<input type="text" class="form-control" placeholder="--" />
						</div>
					</div>
					<label class="control-label">Status <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<v-select :options="['cek', 'okke']" placeholder="Pilih Status"></v-select>
						</div>
					</div> -->
                    <label class="control-label"
                        >Nama <span class="text-danger">*</span></label
                    >
                    <div class="row m-b-15">
                        <div class="col-md-12">
                            <input
                                type="text"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': form.errors.has('name'),
                                }"
                                v-model="form.name"
                                placeholder="Masukkan nama"
                                :disabled="isDisable"
                            />
                            <em
                                v-if="form.errors.has('name')"
                                class="form-text text-danger"
                                >{{ errors.name[0] }}</em
                            >
                        </div>
                    </div>
                    <!-- <label class="control-label">Alamat <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<textarea class="form-control" rows="3" />
						</div>
					</div>
					<label class="control-label">Provinsi <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<v-select :options="['Sumatera Utara']" placeholder="Pilih Provinsi"></v-select>
						</div>
					</div>
					<label class="control-label">Kab./Kota <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<v-select :options="['Medan']" placeholder="Pilih Kab./Kota"></v-select>
						</div>
					</div>
					<label class="control-label">Kecamatan <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<v-select :options="['Medan Selayang']" placeholder="Pilih Kecamatan"></v-select>
						</div>
					</div>
					<label class="control-label">Kelurahan <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<v-select :options="['Tanjung Sari']" placeholder="Pilih Kelurahan"></v-select>
						</div>
					</div> -->
                    <label class="control-label"
                        >Email <span class="text-danger">*</span></label
                    >
                    <div class="row m-b-15">
                        <div class="col-md-12">
                            <input
                                type="email"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': form.errors.has('email'),
                                }"
                                v-model="form.email"
                                placeholder="Masukkan email"
                                :disabled="isDisable"
                            />
                            <em
                                v-if="form.errors.has('email')"
                                class="form-text text-danger"
                                >{{ errors.email[0] }}</em
                            >
                        </div>
                    </div>
                    <label class="control-label"
                        >Password <span class="text-danger">*</span></label
                    >
                    <div class="row m-b-15">
                        <div class="col-md-12">
                            <input
                                type="password"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': form.errors.has('password'),
                                }"
                                v-model="form.password"
                                placeholder="Masukkan password"
                                :disabled="isDisable"
                            />
                            <em
                                v-if="form.errors.has('password')"
                                class="form-text text-danger"
                                >{{ errors.password[0] }}</em
                            >
                        </div>
                    </div>
                    <label class="control-label"
                        >Konfirmasi Password
                        <span class="text-danger">*</span></label
                    >
                    <div class="row m-b-15">
                        <div class="col-md-12">
                            <input
                                type="password"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': form.errors.has('password'),
                                }"
                                v-model="form.password_confirmation"
                                placeholder="Konfirmasi password"
                                :disabled="isDisable"
                            />
                            <em
                                v-if="form.errors.has('password')"
                                class="form-text text-danger"
                                >{{ errors.password[0] }}</em
                            >
                        </div>
                    </div>
                    <!-- <div class="checkbox checkbox-css m-b-30">
                        <div class="checkbox checkbox-css m-b-30">
                            <input
                                type="checkbox"
                                id="agreement_checkbox"
                                value=""
                            />
                            <label for="agreement_checkbox">
                                Dengan klik tombol daftar, anda telah setuju
                                dengan
                                <a href="javascript:;" v-b-modal.modalSyarat
                                    >Syarat & Ketentuan</a
                                >
                                kami.
                            </label>
                        </div>
                    </div> -->
                    <div class="register-buttons">
                        <b-button variant="danger" block type="submit" :disabled="isDisable"> 
                            <span v-if="isLoading"
                                ><b-spinner
                                    variant="light"
                                    small
                                    label="loading"
                                ></b-spinner
                            ></span>
                            Daftar
                        </b-button>
                    </div>
                    <div class="m-t-20 m-b-40 p-b-40 text-inverse">
                        Sudah punya akun? klik
                        <router-link to="/auth/login"
                            ><span class="font-weight-bold h4 text-primary"
                                >LOGIN</span
                            ></router-link
                        >
                        untuk masuk.
                    </div>
                    <hr />
                    <p class="text-center">
                        &copy; Pemerintah Provinsi Sumatera Utara | All Right
                        Reserved 2020
                    </p>
                </form>
            </div>
            <!-- end register-content -->
        </div>
        <!-- end right-content -->

        <!-- Modal Syarat dan Ketentuan -->
        <b-modal id="modalSyarat" title="SYARAT & KETENTUAN">
            <div>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quia,
                aut voluptatum. Quod minus pariatur iste incidunt quae modi
                quibusdam? Nobis animi sit rerum et blanditiis assumenda
                voluptatum incidunt magnam esse. Laudantium dolorem recusandae
                commodi? Minima voluptatum voluptatem eaque error ullam eum,
                saepe reprehenderit odit maiores non, earum dicta dolor hic
                delectus, enim possimus deleniti! Placeat qui iste beatae
                sapiente quidem. Nihil quasi aut tempore labore voluptas beatae
                ad quaerat, alias eum cumque consectetur pariatur accusantium.
                Quaerat, veritatis incidunt quod inventore voluptate iure
                tempora, cum ullam eius officia at recusandae sit. Voluptatum
                illo voluptatem nemo quaerat debitis officiis veritatis eaque
                vero aperiam! Doloribus sequi error facere assumenda sed,
                dolorem ipsum asperiores repellendus aspernatur sint earum,
                inventore quas reiciendis velit fugit eveniet? Neque doloribus
                ullam, ut voluptatem eius facilis accusantium, natus obcaecati
                nobis nulla perferendis minus sunt quod odio dignissimos
                expedita reprehenderit hic doloremque possimus at illo quae.
                Placeat sunt debitis officiis. Quas placeat odio quos at
                consequuntur et quaerat excepturi vero, necessitatibus eveniet
                perferendis impedit possimus, beatae autem minima? Assumenda
                laboriosam numquam perferendis excepturi pariatur temporibus
                ducimus fugiat adipisci ea harum. Molestiae dolores ex, dolorum
                itaque corporis, ea alias veniam rerum praesentium nesciunt
                doloribus? Illum, perferendis beatae eligendi dolorem sit
                debitis similique magni eum! Debitis voluptatum laboriosam aut,
                fugiat officia illo.
            </div>
            <template #modal-footer="{close}">
                <b-button variant="outline-secondary" @click="close()" size="sm"
                    >Tutup</b-button
                >
            </template>
        </b-modal>
        <!-- end Modal -->
    </div>
    <!-- end register -->
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import PageOptions from "@/config/PageOptions.vue";
import Logo from "@/assets/images/logoSumut.png";

export default {
    data() {
        return {
            logoSumut: Logo,
            form: new Form({
                name: "",
                email: "",
                password: "",
                passowrd_confirmation: "",
            }),
            errors: [],
            isLoading: false,
            isDisable: false
        };
    },
    created() {
        PageOptions.pageEmpty = true;
        document.body.className = "bg-white";
    },
    beforeRouteLeave(to, from, next) {
        PageOptions.pageEmpty = false;
        document.body.className = "";
        next();
    },
    methods: {
        register() {
            this.isLoading = true
            this.isDisable = true
            this.form
                .post("/api/register")
                .then((response) => {
                    if (this.form.successful) {
                        this.form.reset();
                        this.$swal({
                            icon: "success",
                            title: "Pendaftaran Akun.",
                            html:
                                "Terima Kasih Telah Melakukan Pendaftaran Akun Pajak Daerah. Silahkan cek email anda untuk melakukan verifikasi.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCancelButton: true,
                            cancelButtonClass: "btn btn-danger",
                            cancelButtonText: "Tutup",
                            confirmButtonText: "Ke Halaman Login",
                            showCloseButton: true,
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push({ name: "Login" });
                            }
                        });
                        this.isLoading = false
                        this.isDisable = false
                    }
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.isLoading = false
                        this.isDisable = false
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else {
                        this.isLoading = false
                        this.isDisable = false
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            text:"ERROR: " + error.response.data.message,
                            confirmButtonText: "OKE",
                            showConfirmButton: true,
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000
                        })
                    }
                });
        },
    },
};
</script>
